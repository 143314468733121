.main-container-terms {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.reticula-terms {
  width: 1019px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-container-terms {
  margin-top: 100px;
  display: flex;
  flex: 1;
  justify-content: center;
}

.logo-terms {
  margin: 0 auto;
  width: 215px;
  height: 65px;
}

.text-container-terms {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.head-title-container-terms {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.head-title-terms {
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: 0.9px;
  text-align: center;
  color: #ffffff;
}

.sub-title-terms {
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #ffffff;
  padding-left: 20px;
}

.terms-text {
  font-family: "Exo 2", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  text-align: justify;
  color: #ffffff;
  padding-left: 20px;
}

@media screen and (min-width: 992px) {
  .logo-container-terms {
    margin-top: 200px;
    width: 100%;
  }

  .head-title-terms {
    font-size: 30px;
  }
  .sub-title-terms {
    font-size: 25px;
  }
  .terms-text {
    font-size: 22px;
  }
}
