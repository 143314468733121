.landing-container {
  width: 100%;
  margin: 0 auto;
  background-image: url("./../../assets/img/fondo-azul.png");
  background-size: auto;
  background-repeat: initial;
  background-position: center center;
  animation-name: displacement;
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.body-content {
  margin: 2rem auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.landing-header-container {
  width: 100%;
  margin-bottom: 100px;
}

.landing-main-container {
  width: 1019px;
}

.landing-aboutUs-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  background-image: url("./../../assets/img/fondo-blanco.png");
  background-size: auto;
  background-position-y: -300px;
  background-position-x: -225px;
  animation-name: displacement;
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  scroll-behavior: smooth;
}
.landing-footer-container {
  width: 100%;
  background-color: #262734;
}

@keyframes displacement {
  0% {
    background-position-x: 0%;
  }
  50% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 0%;
  }
}
