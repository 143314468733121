.button-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 1rem 0;
}

.button-container :hover {
  cursor: pointer;
}

.common-button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #1ac1db;
  color: #2a2c3c;
  font-size: 14px;
  height: 3.2rem;
  border-radius: 63px;
  font-weight: 700;
  object-fit: contain;
  border: none;
}

.common-button-disabled {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #dddddd;
  color: #ffffff;
  font-size: 14px;
  height: 3.2rem;
  border-radius: 63px;
  font-weight: 700;
  object-fit: contain;
  border: none;
}

@media screen and (min-width: 420px) {
  .common-button {
    max-width: 300px;
  }
}
