.simulator-container {
  max-width: 85%;
  margin: 0 auto;
  background-color: #ffffff;
  border: 0.5px solid #dcdcdd;
  border-radius: 6px;
  font-family: "Exo 2", sans-serif;
  padding: 1.8rem 2rem 1.1rem 2rem;
  display: block;
  color: #262734;
}

.css-1hwfws3 {
  padding: 0px !important;
}

.simulator-text {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  text-align: left;
  color: #2a2c3c;
}

.simulator-alert-text {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.11px;
  text-align: right;
  color: #ff0000;
}
.simulator-text-bold {
  font-weight: 900;
  letter-spacing: 0px;
}
.savings-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.savings-text {
  font-size: 12px;
}

.resume-container {
  background-color: #efecec;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;
}
.input-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  border: none;
  height: 42px;
}

.simulator-input {
  flex: 1;
  padding-left: 0.2rem;
  border: solid 1px #d3d0d0;
  max-width: 60%;
}

.country-select-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  border: 1px solid #2a2c3c;
  background-color: #2a2c3c;
  color: #ffffff;
  font-size: 14px;
  max-width: 133px;
}

.country-select-container-option {
  display: flex;
  flex-direction: row;
  flex: 1;
  border: 1px solid #2a2c3c;
  background-color: #2a2c3c;
  font-weight: 600;
  font-size: 14px;
}

.country-select-container-option:hover {
  cursor: pointer;
  background-color: #21222c;
}

.country-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.country-info-container-option {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 96%;
  border-bottom: 1px solid #fcfafa;
}

.last-children-fix {
  border-bottom: none;
}

.country-info-container-option p {
  margin-left: 8px;
}
.flag {
  width: 22px;
  height: 12px;
  object-fit: contain;
  margin-top: 2px;
  margin-left: 8px;
}
.arrow-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.savings-text-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.detail-button-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 12px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.margin-none {
  margin: 2px;
}

.country-name-text {
  margin-left: 10px;
  font-size: 16px;
  font-weight: lighter;
  color: #e4e4e4;
}

.single-value-container {
  box-sizing: border-box;
  display: flex;
  height: 30px;
  flex-direction: row;
  align-items: center;
}

.single-value-flag {
  width: 20px;
  height: 12px;
  object-fit: contain;
  margin-top: 2px;
  margin-left: 8px;
}

.single-value-flag-fix-small {
  margin-left: 1rem;
  width: 25px;
  height: 16px;
  object-fit: contain;
}

@media screen and (min-width: 365px) {
  .single-value-flag-fix-small {
    margin-left: 1.5rem;
  }
}
@media screen and (max-width: 325px) {
  .simulator-container {
    padding: 1rem 1rem 1rem 1rem;
  }
  .simulator-input {
    max-width: 60%;
  }

  .flag {
    width: 20px;
    height: 12px;
    object-fit: contain;
    margin-top: 2px;
    margin-left: 8px;
  }

  .single-value-flag {
    width: 25px;
    height: 16px;
    object-fit: contain;
    margin-top: 2px;
    margin-left: 8px;
  }

  .single-value-flag-fix-small {
    margin-left: 10px;
  }
}

@media screen and (min-width: 400px) {
  .css-1hwfws3 {
    padding: 2px 8px !important;
  }
}

@media screen and (min-width: 580px) {
}

@media screen and (min-width: 992px) {
  .simulator-container {
    max-width: 375px;
    margin: 0 auto;
  }
  .single-value-container {
    width: 60px;
  }
  .savings-text {
    font-size: 12px;
  }
  .simulator-input {
    width: 50%;
    max-width: 250px;
  }

  .country-select-container {
    font-size: 15px;
    justify-content: flex-start;
    max-width: 123px;
    font-weight: 600;
  }
  .flag {
    margin-left: 10px;
    width: 24px;
    height: 14px;
  }

  .detail-button-container {
    font-size: 12px;
  }
  .single-value-flag {
    width: 22px;
    height: 14px;
    object-fit: contain;
    margin-top: 2px;
    margin-left: 8px;
  }
  @-moz-document url-prefix() {
    .single-value-container {
      width: 50px;
    }
    .single-value-flag {
      width: 20px;
      height: 14px;
    }
    .single-value-currency {
      font-size: 13px;
    }
    .country-info-container p {
      font-size: 13px;
    }
    .flag {
      width: 20px;
      height: 14px;
    }
  }
}
