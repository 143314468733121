.footer {
  max-width: 576px;
  height: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.footer-container {
  width: 195px;
  display: block;
  height: auto;
  margin-left: 4%;
  padding: 5px;
  font-family: "Exo 2", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.12px;
  color: #ffffff;
}

.logo-footer-container {
  margin-top: 45px;
  text-align: left;
  margin-left: 15px;
}
.allies-footer-container {
  width: 100%;
  height: 64px;
  line-height: 70px;
  margin-bottom: 60px;
  text-align: center;
  background-color: #21222c;
}

.yadio {
  width: 105px;
  height: 25px;
}

.footer-container ul {
  padding: 0px;
}

.footer-container ul li {
  list-style: none;
  text-align: left;
}

.footer-container ul li a {
  text-decoration: none;
  color: #ffffff;
}

.footer-container ul li a:hover {
  color: #1bbccf;
}

.footer-container a {
  text-decoration: none;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.14px;
  text-align: left;
}

.footer-container a:hover {
  color: #1bbccf;
}

.logo-footer {
  width: 180px;
  height: 50px;
}

.schedule {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.14px;
  text-align: left;
  color: #ffffff;
}

.brands {
  margin-left: 2px;
  margin-right: 23px;
}

.brands:hover {
  transform: scale(1.2, 1.2);
}

.facebook {
  width: 13.2px;
  height: 24.7px;
}

.instagram {
  width: 21.6px;
  height: 21.6px;
}

.twitter {
  width: 24.7px;
  height: 20px;
}

.youtube {
  width: 28.4px;
  height: 20px;
}

.week {
  margin: 0px;
  padding: 0px;
  color: #ffffff;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.14px;
  text-align: left;
}

.title-footer {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  text-align: left;
  color: #1bbccf;
  margin-bottom: 10px;
  margin-top: 10px;
}
.clearfix {
  float: none;
  clear: both;
}

.tooltiptext {
  display: none;
}

@media screen and (min-width: 992px) {
  .footer {
    max-width: 1019px;
    height: 315px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
  }
  .footer-wrap {
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
  }

  .footer-container {
    width: 195px;
    margin: 0px auto;
    display: block;
    height: 168px;
    padding: 5px;
  }

  .logo-footer-container {
    width: 100%;
    text-align: left;
    margin-left: 40px;
  }

  .allies-footer-container {
    width: 100%;
    height: 64px;
    line-height: 70px;
    margin-bottom: 60px;
    text-align: center;
    background-color: #21222c;
  }

  .yadio {
    width: 105px;
    height: 25px;
  }

  .footer-container ul {
    padding: 0px;
  }

  .footer-container ul li {
    list-style: none;
    text-align: left;
  }

  .footer-container ul li a {
    text-decoration: none;
    color: #ffffff;
  }

  .footer-container a {
    text-decoration: none;
    color: #ffffff;
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.14px;
    text-align: left;
  }

  .footer-container ul li a:hover {
    color: #1bbccf;
  }

  .footer-container a {
    text-decoration: none;
  }

  .footer-container a:hover {
    color: #1bbccf;
  }

  .logo-footer {
    width: 191.3px;
    height: 57.3px;
  }

  .schedule {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.14px;
    text-align: left;
    color: #ffffff;
  }

  .brands {
    margin-left: 2px;
    margin-right: 23px;
  }

  .brands:hover {
    transform: scale(1.2, 1.2);
  }

  .facebook {
    width: 13.2px;
    height: 24.7px;
  }

  .instagram {
    width: 21.6px;
    height: 21.6px;
  }

  .twitter {
    width: 24.7px;
    height: 20px;
  }

  .youtube {
    width: 28.4px;
    height: 20px;
  }

  .week {
    margin: 0px;
    padding: 0px;
  }

  .title-footer {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: left;
    color: #1bbccf;
    margin-bottom: 18px;
  }

  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltiptext {
    display: initial;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: white;
    text-align: center;
    padding: 5px 0;
    border-radius: 65px;
    font-family: "Exo 2", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.12px;
    text-align: center;
    color: #2a2c3c;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 130%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;

    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
