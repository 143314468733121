.aboutUs {
  width: 100%;
  height: 1000px;
  position: relative;
  margin-top: 30px;
  padding-left: 12px;
}

.file-aboutUs {
  height: 30%;
  text-align: center;
}

.lock-aboutUs {
  height: 30%;
  text-align: center;
}

.link-aboutUs {
  height: 30%;
  text-align: center;
}

.wrap-aboutUs {
  width: 100%;
  height: 100px;
  text-align: center;
}

.subTitle-wrap-aboutUs {
  width: 100%;
  float: left;
}

.title-aboutUs {
  width: 313px;
  height: 65px;
  font-family: "Exo 2", sans-serif;
  font-size: 33px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.6px;
  text-align: left;
  color: #2a2c3c;
  margin-bottom: 10px;
}

.subTitle-aboutUs {
  width: 426px;
  height: 41px;
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.97;
  letter-spacing: 0.3px;
  text-align: left;
  color: white;
  border-radius: 63px;
  line-height: 35px;
  padding-left: 10px;
  margin-left: 0%;
}

.file-subTitle-aboutUs {
  position: relative;
  max-width: 174px;
  min-width: 168px;
  height: 36px;
  border-radius: 21px;
  background-color: #2a2c3c;
  z-index: 1;
}

.file-subTitle-shadow-aboutUs {
  position: absolute;
  max-width: 175px;
  min-width: 175px;
  height: 42px;
  margin-left: 3.8%;
  margin-top: 15px;
  border-radius: 26px;
  background-color: rgba(42, 44, 60, 0.24);
}

.lock-subTitle-aboutUs {
  position: relative;
  max-width: 175px;
  min-width: 330px;
  height: 41px;
  border-radius: 20px;
  background-color: #2a2c3c;
  z-index: 1;
}

.lock-subTitle-shadow-aboutUs {
  position: absolute;
  max-width: 175px;
  min-width: 330px;
  height: 50px;
  margin-left: 4%;
  margin-top: 14px;
  border-radius: 26px;
  background-color: rgba(42, 44, 60, 0.24);
}

.link-subTitle-aboutUs {
  position: relative;
  max-width: 175px;
  min-width: 200px;
  height: 41px;
  border-radius: 20px;
  background-color: #2a2c3c;
  z-index: 1;
}

.link-subTitle-shadow-aboutUs {
  position: absolute;
  max-width: 175px;
  min-width: 200px;
  height: 50px;
  margin-left: 4%;
  margin-top: 14px;
  border-radius: 26px;
  background-color: rgba(42, 44, 60, 0.24);
}

.text-light {
  font-weight: 100;
}

.text-dark {
  font-weight: 600;
}

.file {
  width: 57.8px;
  height: 80.3px;
}
.link {
  width: 80.3px;
  height: 80.3px;
}
.lock {
  width: 71.3px;
  height: 84.4px;
}
.text-aboutUs {
  width: 85%;
  margin-top: 0px;
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.2px;
  text-align: left;
  color: #2a2c3c;
  margin-left: 5%;
  background-color: rgba(255, 255, 255, 0.85);
}

@media screen and (max-width: 358px) {
  .subTitle-aboutUs {
    font-size: 16px;
  }

  .file-subTitle-aboutUs {
    max-width: 160px;
  }

  .file-subTitle-shadow-aboutUs {
    min-width: 170px;
    margin-left: 3.8%;
  }

  .lock-subTitle-aboutUs {
    min-width: 296px;
  }

  .lock-subTitle-shadow-aboutUs {
    min-width: 299px;
    margin-top: 12px;
  }

  .link-subTitle-aboutUs {
    min-width: 180px;
  }

  .link-subTitle-shadow-aboutUs {
    min-width: 183px;
    margin-top: 12px;
    margin-left: 4%;
  }

  .file {
    width: 47.8px;
    height: 70.3px;
  }
  .link {
    width: 70.3px;
    height: 70.3px;
  }
  .lock {
    width: 61.3px;
    height: 74.4px;
  }
  .text-aboutUs {
    font-size: 16px;
    width: 90%;
  }
}

@media screen and (min-width: 888px) {
  .aboutUs {
    width: 1019px;
    height: 683px;
    margin-top: 60px;
    margin-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .title-aboutUs {
    width: 303px;
    height: 75px;
    font-family: "Exo 2", sans-serif;
    font-size: 50px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.5px;
    text-align: left;
    color: #2a2c3c;
    margin-bottom: 20px;
  }

  .subTitle-aboutUs {
    height: 41px;
    font-family: "Exo 2", sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    line-height: 0.97;
    letter-spacing: 0.3px;
    text-align: left;
    color: white;
    background-color: #2a2c3c;
    border-radius: 63px;
    line-height: 35px;
    padding-left: 10px;
    margin-left: 20px;
  }

  .file-aboutUs {
    width: 100%;
    height: 190px;
  }

  .wrap-aboutUs {
    width: 10%;
    height: 100px;
    text-align: center;
    float: left;
    margin-top: 20px;
    margin-left: 45px;
  }

  .subTitle-wrap-aboutUs {
    width: 80%;
    height: 90%;
    float: left;
  }

  .lock-aboutUs {
    width: 100%;
    height: 190px;
  }

  .link-aboutUs {
    width: 100%;
    height: 190px;
  }

  .file {
    margin-right: 40px;
    width: 47.1px;
    height: 62.9px;
  }
  .link {
    margin-right: 40px;
    width: 64px;
    height: 64px;
  }
  .lock {
    margin-right: 40px;
    width: 58.1px;
    height: 66.4px;
  }

  .file-subTitle-aboutUs {
    position: relative;
    max-width: 272px;
    height: 36px;
    border-radius: 21px;
    background-color: #2a2c3c;
    z-index: 1;
  }

  .file-subTitle-shadow-aboutUs {
    position: absolute;
    min-width: 264px;
    max-width: 264px;
    height: 45px;
    margin-left: 4.5%;
    margin-top: 26px;
    border-radius: 26px;
    background-color: rgba(42, 44, 60, 0.24);
  }

  .lock-subTitle-aboutUs {
    position: relative;
    max-width: 175px;
    min-width: 528px;
    height: 41px;
    border-radius: 20px;
    background-color: #2a2c3c;
    z-index: 1;
  }

  .lock-subTitle-shadow-aboutUs {
    position: absolute;
    max-width: 175px;
    min-width: 528px;
    height: 52px;
    margin-left: 3.7%;
    margin-top: 25px;
    border-radius: 26px;
    background-color: rgba(42, 44, 60, 0.24);
  }

  .link-subTitle-aboutUs {
    position: relative;
    max-width: 320px;
    min-width: 320px;
    height: 41px;
    border-radius: 20px;
    background-color: #2a2c3c;
    z-index: 1;
  }

  .link-subTitle-shadow-aboutUs {
    position: absolute;
    max-width: 315x;
    min-width: 315px;
    height: 52px;
    margin-left: 4.1%;
    margin-top: 24px;
    border-radius: 26px;
    background-color: rgba(42, 44, 60, 0.24);
  }

  .text-light {
    font-weight: 100;
  }

  .text-aboutUs {
    width: 680px;
    height: 50px;
    margin-top: -12px;
    font-family: "Exo 2", sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    text-align: left;
    color: #2a2c3c;
    margin-left: 4%;
  }
}
